import React from 'react';
import './Navbar.css';  // Make sure to create this CSS file in the same folder

const Footer = () => {
    return (
        <footer className="footer">
            ...
            {/* <p>Thank you!</p> */}
            {/* <p>&copy; {new Date().getFullYear()} Civil Sphere</p> */}
        </footer>
    );
};

export default Footer;
